import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
//Components

import WorkCard from "../components/common/WorkCard"
import { Content, Grid } from "../components/sections/Works"
import Layout from "../components/common/Layout"
import { Container } from "../components/globalLayout"
import SEO from "../components/common/SEO"

const StyledSection = styled.section`
  padding: 4rem 0;

  p {
    padding-top: 2rem;
  }
`

const WorksPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulImagePost {
        nodes {
          id
          title
          slug
          desc {
            id
          }
          id
          photo {
            file {
              url
              fileName
            }
            fixed(width: 760, quality: 90) {
              src
              base64
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      allContentfulPlate {
        nodes {
          slug
          headImage {
            fixed(width: 700) {
              srcSet
              srcSetWebp
              src
              srcWebp
            }
          }
        }
      }
      allContentfulWorksPlateDesc {
        edges {
          node {
            id
            workCatDesc {
              id
              workCatDesc
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Container>
        <StyledSection className="illustrations">
          <h1>Illustrations</h1>
          {/* {console.log(data.allContentfulWorksPlateDesc.edges[1].node.workCatDesc.workCatDesc)} */}
          <p>
            {
              data.allContentfulWorksPlateDesc.edges[1].node.workCatDesc
                .workCatDesc
            }
          </p>
          <Grid>
            {data.allContentfulImagePost.nodes.map((posts, idx) => {
              return (
                <WorkCard
                  key={idx}
                  cover={posts.photo.fixed}
                  path={posts.slug}
                  name={posts.title}
                ></WorkCard>
              )
            })}
          </Grid>
        </StyledSection>
        <StyledSection>
          <h1>Plates</h1>

          <p>
            {
              data.allContentfulWorksPlateDesc.edges[0].node.workCatDesc
                .workCatDesc
            }
          </p>
          <Grid>
            {/* {data.allContentfulPlate} */}
            {/* {console.log(data.allContentfulPlate)} */}
            {data.allContentfulPlate.nodes.map((plates, idx) => {
              return (
                <WorkCard
                  className="no-hover"
                  key={idx}
                  cover={plates.headImage.fixed}
                  // path="#"
                  name={plates.title}
                ></WorkCard>
              )
            })}
          </Grid>
        </StyledSection>
      </Container>
    </Layout>
  )
}
export default WorksPage
